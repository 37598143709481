<template lang="pug">
v-card
  img(alt="Garage argentino" src="../assets/img/logo-login.png")
  p ¡Bienvenido! Puedes iniciar sesión aquí abajo.
  v-form(@submit.prevent="onSubmit" lazy-validation)
    v-text-field(v-model="form.username" @keyup="lowercaseUsername" solo label="Usuario" type="email" append-icon="mdi-account-multiple-outline" height="56px" required :rules="usernameRules")
    v-text-field(v-model="form.password" solo label="Contraseña" :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" height="56px" required :rules="passwordRules" @click:append="showPassword = !showPassword")
      v-btn(icon @click="showPassword = !showPassword")
        v-icon mdi-eye
    p.mt-0.pl-1(v-show="incorrect" style="color: red; font-size: 15px;") El usuario o contraseña son incorrectos. Vuelve a intentarlo.
    v-btn(color="#2F58A3" type="submit" min-width="100%" min-height="56px") Ingresar
</template>

<script>
import { mapActions } from 'vuex'
import { mapActions as mapActionsPinia} from 'pinia'
import initializeFirebase from '@/plugins/firebase'
import { useNotificationStore } from '@/stores/api/useNotificationStore'

export default {
  data () {
    return {
      showPassword: false,
      incorrect: false,
      error: null,
      form: {
        username: null,
        password: null
      },
      usernameRules: [
        v => !!v || 'El usuario es requerido'
      ],
      passwordRules: [
        v => !!v || 'La contraseña es requerida'
      ]
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    ...mapActionsPinia(useNotificationStore, ['registerNotificationsDevice']),
    async onSubmit () {
      this.error = null
      this.incorrect = false
      const res = await this.login({ data: this.form })
      if (res.status === 200) {
        this.$router.push({ name: 'Home' })

        // Conectar a notificaciones Firebase al loguear
        const token = await initializeFirebase()
        if (token) {
          this.registerNotificationsDevice({ device_token: token })
        }
      } else {
        this.error = res.data
        this.incorrect = true
      }
    },
    lowercaseUsername () {
      this.form.username = this.form.username.toLowerCase()
    }
  }
}
</script>

<style lang="scss">
.login-container{
  .v-sheet.v-card {
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    min-width: 100%;
    border-radius: 18px;
    min-width: 312px;
    @media (max-width: 960px) {
      min-width:100%;
    }
    img{
      justify-self: center;
      align-self: center;
    }
    p{
      margin-top: 48px;
      color: #464544;
      font-size: 18px;
      line-height: 21px;
    }
    .v-input__control{
      margin-top: 24px;
    }
    .input-password .v-text-field__details {
      display: none;
    }
    a{
      text-decoration: none;
      color: #A3A2A1;
    }
    .v-btn{
      margin-top: 73px;
      margin-bottom: 30px;
      .v-btn__content{
        color: #fff;
      }
    }
  }
}

</style>
