<template lang="pug">
  .login-layout
    .login-container
      login
    .login-content
      img(alt="imagen principal" src="./../assets/img/img-login-content.png")
</template>

<script>
import Login from '../components/Login'
export default {
  components: {
    Login
  }
}
</script>
<style lang="scss" scope>
  .login-layout{
    background: #E8E8E8;
    height: 100%;
    padding: 82px 24px;
    display:flex;
    justify-content: center;
    gap:18px;
    .login-container{
      width:312px;
      @media (max-width: 960px) {
        width:100%;
      }
    }
    .login-content{
      max-height: 600px;
      max-width: 972px;
      img{
        width: 100%;
        @media (max-width: 960px) {
        display: none;
      }
      }
    }
  }
</style>
